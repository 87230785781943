import React from "react";
import barScale from "../assets/aqi-scale.png";
import { getRegulatoryAqiIcon } from "../utils/Scale";

const pmScale = ["0", "50", "100", "150", "200", "300", "500"];
const so2Scale = ["0", "0.1", "0.2", "1.0", "3.0", "5.0", ""];

function determineIconPosition(aqiValue, pollutant) {
	if (pollutant === "so2") {
		if (aqiValue <= 0.2) {
			return (aqiValue / 0.6) * 92;
		} else if (aqiValue <= 1.0) {
			return (aqiValue / 1) * 10 + 34.3333;
		} else if (aqiValue <= 3.0) {
			return ((aqiValue - 1.0) / 2) * ((1 / 6) * 100) + 46;
		} else if (aqiValue <= 5.0) {
			return (aqiValue - 3.0) / 2 + 62.6667;
		} else {
			let value = (aqiValue - 5.0) / 5 + 79.3333;
			if (value > 93) {
				return 93; // don't exceed the max value on the graph
			} else {
				return value;
			}
		}
	} else if (pollutant === "pm25") {
		if (aqiValue <= 200) {
			// The bar is split into 6 sections, each 50 units wide
			return (aqiValue / 300) * 100;
		} else if (aqiValue <= 300) {
			// Just kidding, the fifth section is 100 units, in a 50 unit space
			// So, we find its position in the 50 unit space, then offset it by 2/3 of the entire bar
			return (aqiValue - 200) / 2 + 66.6667;
		} else {
			// The sixth section is 200 units, in a 50 unit space
			// So, we find its position in the 50 unit space, then offset it by 5/6 of the entire bar
			let value = (aqiValue - 300) / 4 + 83.3333;
			if (value > 500) {
				return 500; // don't exceed the max value on the graph
			} else {
				return value;
			}
		}
	}
}

const AirQualityIndexChart = ({ aqiValue, pollutant, scaleFactor }) => {
	let scaleToUse = pollutant === "so2" ? so2Scale : pmScale;
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				marginBottom: "20px",
				paddingLeft: "10px",
			}}
		>
			<img
				src={barScale}
				alt="AQI Scale"
				style={{
					width: "100%",
					maxWidth: "380px",
					marginBottom: "10px",
					marginTop: "10px",
				}}
			/>
			<img
				src={getRegulatoryAqiIcon(aqiValue, pollutant)}
				alt="AQI indicator"
				style={{ width: "5%", position: "absolute", left: `${determineIconPosition(aqiValue, pollutant)}%` }}
			/>
			<div
				style={{
					display: "grid",
					gridTemplateColumns: "repeat(7, 1fr)",
					width: "100%",
					maxWidth: "380px",
					fontSize: (14*scaleFactor)+"px",
				}}
			>
				<div style={{ textAlign: "left" }}>{scaleToUse[0]}</div>
				<div style={{ textAlign: "left" }}>{scaleToUse[1]}</div>
				<div style={{ textAlign: "left", paddingLeft: "20%" }}>{scaleToUse[2]}</div>
				<div style={{ textAlign: "left", paddingLeft: "30%" }}>{scaleToUse[3]}</div>
				<div style={{ textAlign: "left", paddingLeft: "50%" }}>{scaleToUse[4]}</div>
				<div style={{ textAlign: "left", paddingLeft: "70%" }}>{scaleToUse[5]}</div>
				<div style={{ textAlign: "left", paddingLeft: "70%" }}>{scaleToUse[6]}</div>
			</div>
		</div>
	);
};
export default AirQualityIndexChart;
