import React from "react";
import { Chart as ChartJS } from "chart.js/auto";
import { Bar } from "react-chartjs-2";
import { formatChartLabel, formatChartTitle } from "../utils/DateFormat";
import { getValue, getCategory, getCategoryLabel, getRgbColor, roundToNearestTenth } from "../utils/Scale";

const RecentHistoryChart = ({ inputData, offset, pollutantText }) => {
	let chartData = [];
	let chartLabels = [];
	let chartTitles = [];
	let maxValue = -1;

	// Current values for max count, assuming that every data point is filled:
	// 1-hr PM2.5: 24
	// 10-min PM2.5: 144
	// 15-min SO2: 96
	// TODO: Determine if we want to set a maximum value to display.
	const maxCount = inputData.length - 1;

	// Normally the most recent data is at the beginning of the array, so we need to reverse it.
	for (let i = maxCount; i >= 0; --i) {
		const value = getValue(inputData[i]);
		const dst = inputData[i].dateTimeStamp;
		if (value !== undefined && dst !== undefined) {
			if (value > maxValue) {
				maxValue = value;
			}
			chartData.push(value);
			chartLabels.push(formatChartLabel(dst, offset, pollutantText.get("duration").props.children[0].includes("min")));
			chartTitles.push(formatChartTitle(dst, offset, pollutantText.get("duration").props.children[0].includes("min")));
		}
	}
	const data = {
		labels: chartLabels, // X-axis labels
		datasets: [
			{
				label: pollutantText.get("duration"),
				data: chartData, // Y-axis data points
				fill: true,
				backgroundColor: [],
				borderColor: [],
				borderWidth: 1,
				pointHoverRadius: 5,
			},
		],
	};

	data.datasets[0].backgroundColor = data.datasets[0].data.map((aqivalue) => {
		return getRgbColor(pollutantText.get("key"), aqivalue, "67%");
	});

	data.datasets[0].borderColor = data.datasets[0].data.map((aqivalue) => {
		return getRgbColor(pollutantText.get("key"), aqivalue, "67%");
	});

	data.datasets[0].pointHoverBackgroundColor = data.datasets[0].data.map((aqivalue) => {
		return getRgbColor(pollutantText.get("key"), aqivalue, "100%");
	});

	data.datasets[0].pointHoverBorderColor = data.datasets[0].data.map((aqivalue) => {
		return getRgbColor(pollutantText.get("key"), aqivalue, "100%");
	});

	// Chart options
	const options = {
		responsive: true,
		scales: {
			y: {
				beginAtZero: true,
				// max: Math.min(maxValue, roundToNearestTenth(maxValue * 2))
				// max: maxValue
				max: getCategory(pollutantText.get("key"), maxValue).max
			},
			x: {
				ticks: {
					autoSkip: true,
					autoSkipPadding: 0,
					minRotation: 90,
					font: {
						size: 11
					}
				}
			}
		},
		plugins: {
			legend: {
				display: false,
			},
			tooltip: {
				callbacks: {
					title: function (context) {
						return null;
					},
					label: function (context) {
						let multistringText = ["  " + chartTitles[context.dataIndex]];
						if (pollutantText.get("scale").includes("AQI")) {
							multistringText.push("  " + pollutantText.get("scale") + ":  " + context.parsed.y);
						} else {
							multistringText.push("  SO2: " + context.parsed.y + " " + pollutantText.get("scale"));
						}
						multistringText.push("  Category: " + getCategoryLabel(pollutantText.get("key"), context.parsed.y));
						return multistringText;
					},
					labelColor(tooltipItem, chart) {
						return {
							borderColor: getRgbColor(pollutantText.get("key"), tooltipItem.raw, "100%"),
							backgroundColor: getRgbColor(pollutantText.get("key"), tooltipItem.raw, "100%"),
						};
					},
				},
			},
		},
	};

	return <Bar style={{ width: "95%", paddingLeft: "10px" }} data={data} options={options} />;
};

export default RecentHistoryChart;
