import React, { useState } from "react";
import "../styles/AqiList.css";
import aqiChartIcon from "../assets/aqi-chart.png";
import good from "../assets/circle-good-bb.png";
import moderate from "../assets/circle-moderate-bb.png";
import usg from "../assets/circle-usg-bb.png";
import unhealthy from "../assets/circle-unhealthy-bb.png";
import veryUnhealthy from "../assets/circle-veryunhealthy-bb.png";
import hazardous from "../assets/circle-hazardous-bb.png";
import na from "../assets/circle-na-bb.png";
import goodADA from "../assets/circle-good-bb-ada.png";
import moderateADA from "../assets/circle-moderate-bb-ada.png";
import usgADA from "../assets/circle-usg-bb-ada.png";
import unhealthyADA from "../assets/circle-unhealthy-bb-ada.png";
import veryUnhealthyADA from "../assets/circle-veryunhealthy-bb-ada.png";
import hazardousADA from "../assets/circle-hazardous-bb-ada.png";
import { getPm25Color, getPm25AdaColor, getCategoryTextColor } from "../utils/Scale";

function AqiList({ showAdaIcons }) {
	return (
		<div className="aqiListShown">
			<div className="aqiListHeader">
				<span className="headerText">ADVISORY LEVELS</span>
			</div>
			<div className="aqiListItem" style={{background: showAdaIcons ? getPm25AdaColor(1) : getPm25Color(1)}}>
				<span className="aqiListItemText" style={{color: getCategoryTextColor("pm25", 1)}}>Good</span>
			</div>
			<div className="aqiListItem" style={{background: showAdaIcons ? getPm25AdaColor(51) : getPm25Color(51)}}>
				<span className="aqiListItemText" style={{color: getCategoryTextColor("pm25", 51)}}>Moderate</span>
			</div>
			<div className="aqiListItem" style={{background: showAdaIcons ? getPm25AdaColor(101) : getPm25Color(101)}}>
				<span className="aqiListItemTextSmall" style={{color: getCategoryTextColor("pm25", 101)}}>Unhealthy for Sensitive Groups</span>
			</div>
			<div className="aqiListItem" style={{background: showAdaIcons ? getPm25AdaColor(151) : getPm25Color(151)}}>
				<span className="aqiListItemText" style={{color: getCategoryTextColor("pm25", 151)}}>Unhealthy</span>
			</div>
			<div className="aqiListItem" style={{background: showAdaIcons ? getPm25AdaColor(201) : getPm25Color(201)}}>
				<span className="aqiListItemText" style={{color: getCategoryTextColor("pm25", 201)}}>Very Unhealthy</span>
			</div>

			<div className="aqiListItem" style={{background: showAdaIcons ? getPm25AdaColor(301) : getPm25Color(301)}}>
				<span className="aqiListItemText" style={{color: getCategoryTextColor("pm25", 301)}}>Hazardous</span>
			</div>
			<div className="aqiListItem" style={{background: showAdaIcons ? getPm25AdaColor() : getPm25Color()}}>
				<span className="aqiListItemText" style={{color: getCategoryTextColor("pm25", "")}}>No Data</span>
			</div>
		</div>
	);
}

export default AqiList;
