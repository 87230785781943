import React, { useState, forwardRef } from "react";
import AirQualityIndexChart from "./AqiChart";
import RecentHistoryChart from "./HistoryChart";
import ImgStable from "../assets/stable.png";
import ImgDecreasing from "../assets/decreasing.png";
import ImgIncreasing from "../assets/increasing.png";
import ImgDownload from "../assets/download.png";
import imgArrowDownBlue from "../assets/arrow-down-blue.png";
import imgArrowUpBlue from "../assets/arrow-up-blue.png";
import { getColor, getValue } from "../utils/Scale";
import { getCategoryLabel, worstPollutant, getMonitorTypeString, getCategoryTextColor } from "../utils/Scale";
import { getText, getPollutantTextSize, getAgencyText } from "../utils/Constants";
import { formatDateforDetailPanel } from "../utils/DateFormat";
import { fetchHistoricData } from "../utils/FetchDataAndCombine";
import { saveAs } from 'file-saver';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import "../styles/RecentHistory.css";
import "../styles/DetailPanel.css";

function getDataSet(site, pollutant) {
	if (pollutant === "pm25") {
		if (site.relatedData.pm25.length === 0) {
			// TODO: Fix this...
			// We have a SO2 site that's errantly in the PM25 layer
			return site.relatedData.so2
		}
		return site.relatedData.pm25;
	} else if (pollutant === "so2") {
		if (site.relatedData.so2.length === 0) {
			// TODO: Fix this...
			// We have a PM25 site that's errantly in the SO2 layer
			return site.relatedData.pm25
		}
		return site.relatedData.so2;
	}
}

const DetailPanel = forwardRef(({ advisory, detailHeight, navHeaderHeight, site, isVisible, setVisible, scaleFactor }, ref) => {
	if (!site) {
		return null;
	}
	const togglePanel = () => {
		setVisible(!isVisible);
	};

	let shiftDown = advisory ? `calc(${navHeaderHeight} + 99px)` : `calc(${navHeaderHeight} + 1px)`;
	let panelHeight = `calc(${detailHeight}vh - ${shiftDown}px)`;
	let hasElevation = site.elevation !== undefined && site.elevation !== null;

	return (
		<div
			ref={ref}
			className="detailPanel"
			style={{
				height: `calc(100vh - ${shiftDown})`,
				maxHeight: panelHeight
			}}
		>
			<div className="siteParent">
				<div style={{display: "inline-flex"}} onClick={togglePanel}>
					<div className="detailSiteName">{site.name}</div>
					<span className="headerIcon">{"\u2715"}</span>
				</div>
				{hasElevation && <div className="detailSiteElevation">{site.elevation} feet</div>}
				<div className="detailMonitorId">Monitor ID: {site.stationid}</div>
				<div className="detailProvider">Provider: {getAgencyText(site.agencycode)}</div>
				<div className="detailType">Type: {getMonitorTypeString(site)}</div>
			</div>
			<PollutantSwitcher site={site} scaleFactor={scaleFactor} />
			<FAQs />
			<Disclaimers />
		</div>
	);
});

const PollutantSwitcher = ({ site, scaleFactor }) => {
	const currentWorstPollutant = worstPollutant(site.relatedData.pm25[0], site.relatedData.so2[0]);
	let showPM25 = false;
	let showSO2 = false;

	if (site.relatedData.pm25.length > 0) {
		showPM25 = true;
	}
	if (site.relatedData.so2.length > 0) {
		showSO2 = true;
	}
	
	// TODO: Add a conditional check on currentWorstPollutant and swap the order if we need to show
	// based on the the currently worst pollutant
	return (
		<div>
			{showPM25 && <PollutantContent site={site} pollutant={"pm25"} scaleFactor={scaleFactor} />}
			{showSO2 && <PollutantContent site={site} pollutant={"so2"} scaleFactor={scaleFactor} />}
		</div>
	)
};

const PollutantContent = ({site, pollutant, scaleFactor}) => {
	const renderContent = !(site.agencycode === "PAR" && pollutant === "so2");
	return (
	<div>
		{renderContent ? (
			<div>
				<AirQualityIndex site={site} pollutant={pollutant} scaleFactor={scaleFactor} />
				<RecentHistory site={site} pollutant={pollutant} />
			</div>) : (<div style={{display: "none"}}></div>)
		}
	</div>
	)
};

// AirQualityIndex component
const AirQualityIndex = ({ site, pollutant, scaleFactor }) => {
	const pollutantText = getText(pollutant);
	const pollutantTextSize = getPollutantTextSize(pollutant, scaleFactor);
	const dataSet = getDataSet(site, pollutant);
	const currentValue = getValue(dataSet[0]);
	const lastValue = getValue(dataSet[1]);
	let trend = "";
	let trendIcon = null;
	const renderContent = !(pollutant === "so2" && dataSet.length < 1);
	const formattedValue = (renderContent && pollutant === "so2") ? currentValue.toFixed(3) : currentValue;

	//(((7-15)/15)*100)
	const valueDiff = (((lastValue - currentValue) / currentValue) * 100);
	if (Math.abs(valueDiff) <= 10) {
		trend = "Stable";
		trendIcon = ImgStable;
	} else if (valueDiff < 0) {
		trend = "Increasing";
		trendIcon = ImgIncreasing;
	} else {
		trend = "Decreasing";
		trendIcon = ImgDecreasing;
	}

	return (
		<div>
			{renderContent ? (
				<div className="aqiParent" >
					<div className="aqiNested">
						<div className="aqiNestedLevelTwo"
							 style={{ backgroundColor: getColor(pollutant, currentValue), color: getCategoryTextColor(pollutant, currentValue)}}
						>
							<div className="aqiPollutant">
								{pollutantText.get("centeredAbbrev")}
							</div>
							<div className="aqiValueParent" >
								<div className="aqiFormattedValue" style={{ fontSize: pollutantTextSize }} >
									{formattedValue}
								</div>
								<div className="aqiScale" >
									{pollutantText.get("scale")}
								</div>
							</div>
						</div>
						<div className="aqiCategoryParent" >
							<div className="aqiCategory" style={{fontSize: getCategoryLabel(pollutant, currentValue).includes("Sensitive") ? (24*scaleFactor)+"px" : (48*scaleFactor)+"px"}}>
								{/*(24*scaleFactor)+"px" : (48*scaleFactor)+"px"*/}
								{getCategoryLabel(pollutant, currentValue)}
							</div>
							<div className="aqiFormatDatePanel">
								{formatDateforDetailPanel(dataSet[0].dateTimeStamp, site.utcoffset, pollutantText.get("timezone"))}
							</div>
							<div className="aqiTrendParent">
								<img src={trendIcon} alt="Stable" className="aqiImg" />
								<span className="aqiTrend">{trend}</span>
							</div>
						</div>
					</div>
					<div className="aqiChart">
						<AirQualityIndexChart aqiValue={currentValue} pollutant={pollutant} scaleFactor={scaleFactor} />
					</div>
				</div>) : 
				(<div style={{display: "none"}}></div>)
			}
		</div>
	)
};

// RecentHistory component
const RecentHistory = ({ site, pollutant }) => {
	const pollutantText = getText(pollutant);
	const dataSet = pollutant === "so2" ? site.relatedData.so2 : site.relatedData.pm25;

	const convertJsonToCsv = (json) => {
		const items = json;
		const replacer = (key, value) => (value === null ? '' : value);
		const header = Object.keys(items[0]);
		const csv = [
		  header.join(','),
		  ...items.map((row) => header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(','))
		].join('\r\n');
	  
		return csv;
	  };
	  
	  const downloadCsv = (json, filename = 'data.csv') => {
		const csv = convertJsonToCsv(json);
		const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
		saveAs(blob, filename);
	  };
	
	const [calendarIsOpen, setCalendarIsOpen] = useState(false);
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
  
	const toggleCalendar = () => {
	  setCalendarIsOpen(!calendarIsOpen);
	};
  
	const handleDownload = async () => {
	  console.log('Download data from ' + site.name + ' with dates:', startDate, endDate);
	  const data = await fetchHistoricData(site, startDate, endDate);
	  downloadCsv(data, site.name.replace(" ", "") + "_" + startDate + "_" + endDate + ".csv");
	  toggleCalendar();
	};

	return (
		<div style={{overflow: 'visible'}}>
			<h2 className="historyHeaderParent" >
				<div className="historyHeader">RECENT HISTORY</div>
				<div className="historyHeaderPollutant">{pollutantText.get("duration")}</div>
				<div className="historyHeaderCalendarToggle" onClick={toggleCalendar}>
					<img src={ImgDownload} alt="Stable" className="historyHeaderImg" />
					<span className="historyHeaderDownload">Download</span>
				</div>
			</h2>
			<div className="downloadParent" style={{display: calendarIsOpen ? "block" : "none"}}>
				<div>
					<h2 className="downloadHeaderParent">
						<div className="downloadHeader">DOWNLOAD DATA</div>
						<span className="downloadHeaderCalendarToggle" onClick={toggleCalendar}>{"\u2715"}</span>
					</h2>
					<div className="downloadSelectText">
						Select date range
					</div>
					<div className="downloadDatePicker">
						<DatePicker
							selected={startDate}
							onChange={(date) => setStartDate(date)}
							selectsStart
							startDate={startDate}
							endDate={endDate}
							minDate={new Date("01-01-2024")}
							maxDate={new Date()}
							placeholderText="Select start date (Backfill in progress, 1/1/24 to present currently available)"
						/>
						<span> to </span>
						<DatePicker
							selected={endDate}
							onChange={(date) => setEndDate(date)}
							selectsEnd
							startDate={startDate}
							endDate={endDate}
							minDate={startDate}
							maxDate={new Date()}
							placeholderText="Select end date"
						/>
					</div>
					<div className="downloadOptions">
						<div className="downloadCancel" onClick={toggleCalendar}>Cancel</div>
						<div className="downloadHandler" onClick={handleDownload}>Download</div>
					</div>
				</div>
			</div>
			<RecentHistoryChart inputData={dataSet} offset={site.utcoffset} pollutantText={pollutantText} />,
		</div>
	);
};
// FAQs component
const FAQs = () => {
	const [isCollapsedPrimary, setIsCollapsedPrimary] = useState(false);

	const toggleCollapsePrimary = () => {
		setIsCollapsedPrimary(!isCollapsedPrimary);
	};

	return (
		<div className="faqParent" onClick={toggleCollapsePrimary}>
			<h2 className="faqHeader">
				<span>LEARN MORE ABOUT AIR QUALITY</span>
				<span className="faqHeaderImgSpan">
					{isCollapsedPrimary ? (
						<img src={imgArrowUpBlue} alt="Expand this section" className="faqHeaderImgExpand" />
					) : (
						<img src={imgArrowDownBlue} alt="Collapse this section" className="faqHeaderImgCollapse" />
					)}
				</span>
			</h2>
			<div style={{ display: isCollapsedPrimary ? "none" : "block" }}>
				<div className="faqLinks">
					<a href="https://air.doh.hawaii.gov/home/map" target="_blank" rel="noreferrer" className="faqDohAqLink">
						Hawaii Ambient Air Quality Data
					</a><br />
					<a href="https://fire.airnow.gov/" target="_blank" rel="noreferrer" className="faqFireSmokeMapLink">
						AirNow Fire and Smoke Map
					</a><br />
					<a href="https://www.nps.gov/havo/air-quality-alert.htm" target="_blank" rel="noreferrer" className="faqHvoLink">
						Air Quality at Hawaii Volcanoes National Park
					</a><br />
					<a href="https://vog.ivhhn.org/" target="_blank" rel="noreferrer" className="faqDashboardLink">
						Hawaii Interagency Vog Information Dashboard
					</a>
				</div>
			</div>
		</div>
	);
};

// Disclaimers component
const Disclaimers = () => (
	<div className="disclaimers">
		<h2 className="disclaimersHeader">
			DISCLAIMERS
		</h2>
		<div className="disclaimersContent">
			<p>
				The data on these web pages is preliminary and unofficial. It awaits review and validation by qualified staff. The data may be revised or invalidated after review. The Department makes every effort to assert the validity and integrity of the data, but data can be affected by equipment malfunctions, technical difficulties, and other unforeseen circumstances.
			</p>
		</div>
	</div>
);

export default DetailPanel;
