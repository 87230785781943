function convertTo12Hour(hours) {
	if (hours === 0) {
		return 12;
	}
	return hours > 12 ? hours - 12 : hours;
}

function getMonthName(month) {
	const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
	return monthNames[month];
}

function getMeridiem(hours) {
	return hours >= 12 ? "pm" : "am";
}

export function formatDateForURL(dataDate) {
	const pad = (number) => number.toString().padStart(2, "0");

	const year = dataDate.getUTCFullYear();
	const month = pad(dataDate.getUTCMonth() + 1);
	const day = pad(dataDate.getUTCDate());
	const hours = pad(dataDate.getUTCHours());
	const minutes = pad(dataDate.getUTCMinutes());
	const seconds = pad(dataDate.getUTCSeconds());

	return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
}

export function formatDateforDetailPanel(dataDate, offset, timezone) {
	const dateString = dataDate + "Z";
	const utcDate = new Date(dateString);
	const browserOffset = utcDate.getTimezoneOffset() / 60;
	utcDate.setHours(utcDate.getHours() + offset + browserOffset);

	const hours = utcDate.getHours();
	const month = getMonthName(utcDate.getMonth());
	const day = utcDate.getDate();

	return ` ${month} ${day}, ${convertTo12Hour(hours)}${getMeridiem(hours)} ${timezone}`;
}

export function formatChartLabel(dataDate, offset, useMinutes=false) {
	const dateString = dataDate + "Z";
	const utcDate = new Date(dateString);
	const browserOffset = utcDate.getTimezoneOffset() / 60;
	utcDate.setHours(utcDate.getHours() + offset + browserOffset);

	const hours = utcDate.getHours();
	const minutes = utcDate.getMinutes();
	const minutesToUse = useMinutes ? ":" + String(minutes).padStart(2, '0') : "";

	return `${convertTo12Hour(hours)}${minutesToUse}${getMeridiem(hours)}`;
}

export function formatChartTitle(dataDate, offset, useMinutes=false) {
	const dateString = dataDate + "Z";
	const utcDate = new Date(dateString);
	const browserOffset = utcDate.getTimezoneOffset() / 60;
	utcDate.setHours(utcDate.getHours() + offset + browserOffset);

	const hours = utcDate.getHours();
	const month = getMonthName(utcDate.getMonth());
	const day = utcDate.getDate();
	const year = utcDate.getYear();
	const minutes = utcDate.getMinutes();
	const minutesToUse = useMinutes ? ":" + String(minutes).padStart(2, '0') : "";

	return `${convertTo12Hour(hours)}${minutesToUse}${getMeridiem(hours)} ${month} ${day}, ${year + 1900}`;
}

export function subtractDays(dataDate, days) {
	return new Date(dataDate.getTime() - days * 24 * 60 * 60 * 1000);
}

export function subtractHours(dataDate, hours) {
	return new Date(dataDate.getTime() - hours * 60 * 60 * 1000);
}