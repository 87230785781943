import React, { useEffect, useState, useRef } from "react";
import hiLogo from "../assets/hi.png";
import dohLogo from "../assets/doh.png";
import adaImg from "../assets/ada.png";
import adaSelectedImg from "../assets/ada-selected.png";
import faqImg from "../assets/faq.png";
import faqSelectedImg from "../assets/faq-selected.png";
import filterImg from "../assets/filter.png"
import filterSelectedImg from "../assets/filter-selected.png"

import searchImg from "../assets/search.png";
import grayCircleBb from "../assets/circle-na-bb.png";
import graySquareBb from "../assets/square-na-bb.png";
import grayTriangleBb from "../assets/triangle-na-bb.png";
import aqiColorWheel from "../assets/aqi-color-wheel.png";
import aerial from "../assets/aerial.png";
import terrain from "../assets/terrain.png";
import streets from "../assets/streets.png";
import useOutsideClick from "../utils/UseOutsideClick";
import "../styles/NavBar.css";

const NavBar = ({ monitorLayers, setMonitorLayers, mapSettings, setMapSettings, adaIcons, setAdaIcons }) => {
	const [filterLayers, setFilterLayers] = useState(false);
	const [showADA, setShowADA] = useState(false);
	const [showFAQ, setShowFAQ] = useState(false);
	const [isPm25regulatoryChecked, setIsPm25regulatoryChecked] = useState(true);
	const [isPm25temporaryChecked, setIsPm25temporaryChecked] = useState(true);
	const [isPm25nonregulatoryChecked, setIsPm25nonregulatoryChecked] = useState(true);
	const [isSo2regulatoryChecked, setIsSo2regulatoryChecked] = useState(true);
	const [isSo2nonregulatoryChecked, setIsSo2nonregulatoryChecked] = useState(true);
	const [isPm25Checked, setIsPm25Checked] = useState(true);
	const [isSo2Checked, setIsSo2Checked] = useState(true);
	const [isBothChecked, setIsBothChecked] = useState(true);
	const layerDropdownRef = useRef();
	const adaDropdownRef = useRef();
	const faqDropdownRef = useRef();

	const handleMonitorLayerChange = (event, toggleLayers) => {
		setMonitorLayers(toggleLayers);
	};
	const handleMapLayerChange = (event, newMapLayer) => {
		setMapSettings('mapTypeId', newMapLayer);
	};
	const handleDropdownClick = (event) => {
		event.stopPropagation();
	};

	useEffect(() => {
		setIsSo2Checked(isSo2regulatoryChecked && isSo2nonregulatoryChecked);
		setIsPm25Checked(isPm25regulatoryChecked && isPm25temporaryChecked && isPm25nonregulatoryChecked);
		setIsBothChecked(
			isPm25regulatoryChecked &&
				isPm25temporaryChecked &&
				isPm25nonregulatoryChecked &&
				isSo2regulatoryChecked &&
				isSo2nonregulatoryChecked
		);
	}, [
		isPm25regulatoryChecked,
		isPm25temporaryChecked,
		isPm25nonregulatoryChecked,
		isSo2regulatoryChecked,
		isSo2nonregulatoryChecked,
	]);
	
	const handleLayerGroupChange =
		(boxSetter, ...boxes) =>
		(value) => {
			boxSetter(value);
			boxes.forEach((setter) => setter(value));
		};
	const handleIndividualLayerChange = (setter) => (e) => {
		setter(e.target.checked);
	};
	useOutsideClick(layerDropdownRef, () => {
		setFilterLayers(false);
	});
	useOutsideClick(adaDropdownRef, () => {
		setShowADA(false);
	});
	useOutsideClick(faqDropdownRef, () => {
		setShowFAQ(false);
	});

	/*****************************************************************
	This is the code for the search box that we're removing until the
	client decides whether or not to use it.
			<div className="navBarSearchBox">
				<input
					style={{ backgroundImage: `url(${searchImg})` }}
					type="text"
					placeholder="Search for a location"
					id="nav_search"
					name="nav_search"
				/>
			</div>
	 *****************************************************************/

	/***
	 * Here's the filter entry for both pollutants
	
									<label
										onClick={(e) =>
											handleMonitorLayerChange(e, [["pm25regulatory", isPm25regulatoryChecked], ["pm25temporary", isPm25temporaryChecked], ["pm25nonregulatory", isPm25nonregulatoryChecked],["so2regulatory", isSo2regulatoryChecked], ["so2nonregulatory", isSo2nonregulatoryChecked]])
										}
										className="spaciousStart"
									>
										<div className="spaciousText">
											<span>
												Both SO<sub style={{ verticalAlign: "sub" }}>2</sub> and PM
												<sub style={{ verticalAlign: "sub" }}>2.5</sub>
											</span>
										</div>
										<div className="navBarMenuDropdownItemInputBox">
											<input
												type="checkbox"
												checked={isBothChecked}
												onChange={(e) =>
													handleLayerGroupChange(
														setIsBothChecked,
														setIsPm25regulatoryChecked,
														setIsPm25temporaryChecked,
														setIsPm25nonregulatoryChecked,
														setIsSo2regulatoryChecked,
														setIsSo2nonregulatoryChecked
													)(e.target.checked)
												}
												style={{ transform: "scale(2)" }}
											/>
										</div>
									</label>
	 */

	/**
	 * Basemap code
	<div>
								<div className="navBarMenuDropdownHeader">BASEMAPS</div>
								<div className="navBarMenuDropdownItem">
									<label onClick={(e) => handleMapLayerChange(e, "satellite")} className="spaciouStart">
										<div className="navBarMenuDropdownItemImageBox">
											<img src={aerial} alt="Regulatory No Data icon" />
										</div>
										<div className="navBarMenuDropdownItemTextBox">
											<span>Aerial</span>
										</div>
										<div className="navBarMenuDropdownItemInputBox">
											<input
												checked={mapSettings.mapTypeId === "satellite"}
												onChange={(e) => handleMapLayerChange(e, "satellite")}
												type="radio"
												id="baemaps_aerial"
												name="baemaps"
												style={{ transform: "scale(2)" }}
											/>
										</div>
									</label>
									<label onClick={(e) => handleMapLayerChange(e, "terrain")} className="spaciou">
										<div className="navBarMenuDropdownItemImageBox">
											<img src={terrain} alt="Regulatory No Data icon" />
										</div>
										<div className="navBarMenuDropdownItemTextBox">
											<span>Terrain</span>
										</div>
										<div className="navBarMenuDropdownItemInputBox">
											<input
												checked={mapSettings.mapTypeId === "terrain"}
												onChange={(e) => handleMapLayerChange(e, "terrain")}
												type="radio"
												id="baemaps_terrain"
												name="baemaps"
												style={{ transform: "scale(2)" }}
											/>
										</div>
									</label>
									<label onClick={(e) => handleMapLayerChange(e, "roadmap")} className="spaciouEnd">
										<div className="navBarMenuDropdownItemImageBox">
											<img src={streets} alt="Regulatory No Data icon" />
										</div>
										<div className="navBarMenuDropdownItemTextBox">
											<span>Streets</span>
										</div>
										<div className="navBarMenuDropdownItemInputBox">
											<input
												checked={mapSettings.mapTypeId === "roadmap"}
												onChange={(e) => handleMapLayerChange(e, "roadmap")}
												type="radio"
												id="baemaps_streets"
												name="baemaps"
												style={{ transform: "scale(2)" }}
											/>
										</div>
									</label>
								</div>
							</div>
	 */

	return (
		<nav className="navBar">
			<div className="navBarLogoTitle">
				<div className="navBarLogoBox">
					<img src={hiLogo} alt="State of Hawaii shield" />
					<img src={dohLogo} alt="Hawaii State Department of Health logo" />
				</div>
				<div className="navBarTextBox">
					<h4 id="nav_text_doh" name="nav_text_doh">
						Hawaii State Department of Health
					</h4>
					<h1 id="nav_text_hi" name="nav_text_hi">
						Statewide Current SO<sub style={{ verticalAlign: "sub" }}>2</sub> and PM<sub style={{ verticalAlign: "sub" }}>2.5</sub> Conditions
					</h1>
				</div>
			</div>
			<div className="navBarMenuBox">
				<div className="navBarMenuItem" ref={layerDropdownRef} onClick={() => setFilterLayers(!filterLayers)}>
					<button className={filterLayers ? "navButtonClicked" : "navButton"} id="nav_filter" name="nav_filter">
						<img className="navButtonIcon" src={filterLayers ? filterSelectedImg : filterImg} alt="Filter button" />
						<span className="navBarMenuItem text">Filters</span>
					</button>
					{filterLayers && (
						<div
							onClick={(e) => handleDropdownClick(e)}
							className={`navBarMenuDropdown filterMenu`}
							id="nav_filter_dropdown"
							name="nav_filter_dropdown"
						>
							<div>
								<div className="navBarMenuDropdownHeader">MONITOR TYPE</div>
								<div className="navBarMenuDropdownItem">
									<label onClick={(e) => handleMonitorLayerChange(e, [["pm25regulatory",isPm25regulatoryChecked]])} className="spaciousStart">
										<div className="navBarMenuDropdownItemImageBox">
											<img src={grayCircleBb} alt="Regulatory No Data icon" />
										</div>
										<div className="navBarMenuDropdownItemTextBox">
											<span>
												"Circle" – PM<sub style={{ verticalAlign: "sub" }}>2.5</sub> Regulatory
											</span>
										</div>
										<div className="navBarMenuDropdownItemInputBox">
											<input
												type="checkbox"
												checked={isPm25regulatoryChecked}
												onChange={handleIndividualLayerChange(setIsPm25regulatoryChecked)}
												style={{ transform: "scale(2)" }}
											/>
										</div>
									</label>
									<label onClick={(e) => handleMonitorLayerChange(e, [["pm25temporary", isPm25temporaryChecked]])} className="spacious">
										<div className="navBarMenuDropdownItemImageBox">
											<img src={grayTriangleBb} alt="Regulatory No Data icon" />
										</div>
										<div className="navBarMenuDropdownItemTextBox">
											<span>
												"Triangle" – PM<sub style={{ verticalAlign: "sub" }}>2.5</sub> Temporary
											</span>
										</div>
										<div className="navBarMenuDropdownItemInputBox">
											<input
												type="checkbox"
												checked={isPm25temporaryChecked}
												onChange={handleIndividualLayerChange(setIsPm25temporaryChecked)}
												style={{ transform: "scale(2)" }}
											/>
										</div>
									</label>
									<label onClick={(e) => handleMonitorLayerChange(e, [["pm25nonregulatory", isPm25nonregulatoryChecked]])} className="spacious">
										<div className="navBarMenuDropdownItemImageBox">
											<img src={graySquareBb} alt="Regulatory No Data icon" />
										</div>
										<div className="navBarMenuDropdownItemTextBox">
											<span>
												"Square" – PM<sub style={{ verticalAlign: "sub" }}>2.5</sub> Non-regulatory
											</span>
										</div>
										<div className="navBarMenuDropdownItemInputBox">
											<input
												type="checkbox"
												checked={isPm25nonregulatoryChecked}
												onChange={handleIndividualLayerChange(setIsPm25nonregulatoryChecked)}
												style={{ transform: "scale(2)" }}
											/>
										</div>
									</label>
									<label onClick={(e) => handleMonitorLayerChange(e, [["so2regulatory", isSo2regulatoryChecked]])} className="spacious">
										<div className="navBarMenuDropdownItemImageBox">
											<img src={grayCircleBb} alt="Regulatory No Data icon" />
										</div>
										<div className="navBarMenuDropdownItemTextBox">
											<span>
												"Circle" – SO<sub style={{ verticalAlign: "sub" }}>2</sub> Regulatory
											</span>
										</div>
										<div className="navBarMenuDropdownItemInputBox">
											<input
												type="checkbox"
												checked={isSo2regulatoryChecked}
												onChange={handleIndividualLayerChange(setIsSo2regulatoryChecked)}
												style={{ transform: "scale(2)" }}
											/>
										</div>
									</label>
									<label onClick={(e) => handleMonitorLayerChange(e, [["so2nonregulatory", isSo2nonregulatoryChecked]])} className="spaciousEnd">
										<div className="navBarMenuDropdownItemImageBox">
											<img src={graySquareBb} alt="Regulatory No Data icon" />
										</div>
										<div className="navBarMenuDropdownItemTextBox">
											<span>
												"Square" – SO<sub style={{ verticalAlign: "sub" }}>2</sub> Non-regulatory
											</span>
										</div>
										<div className="navBarMenuDropdownItemInputBox">
											<input
												type="checkbox"
												checked={isSo2nonregulatoryChecked}
												onChange={handleIndividualLayerChange(setIsSo2nonregulatoryChecked)}
												style={{ transform: "scale(2)" }}
											/>
										</div>
									</label>
								</div>
							</div>
							<div>
								<div className="navBarMenuDropdownHeader">POLLUTANTS</div>
								<div className="navBarMenuDropdownItem">
									<label
										onClick={(e) => handleMonitorLayerChange(e, [["so2regulatory", isSo2regulatoryChecked], ["so2nonregulatory", isSo2nonregulatoryChecked]])}
										className="spaciousStart"
									>
										<div className="spaciousText">
											<span>
												SO<sub style={{ marginRight: "-8px", verticalAlign: "sub" }}>2</sub>
											</span>
											<span>Sulfur Dioxide</span>
										</div>
										<div className="navBarMenuDropdownItemInputBox">
											<input
												type="checkbox"
												checked={isSo2Checked}
												onChange={(e) =>
													handleLayerGroupChange(
														setIsSo2Checked,
														setIsSo2regulatoryChecked,
														setIsSo2nonregulatoryChecked
													)(e.target.checked)
												}
												style={{ transform: "scale(2)" }}
											/>
										</div>
									</label>
									<label
										onClick={(e) => handleMonitorLayerChange(e, [["pm25regulatory", isPm25regulatoryChecked], ["pm25temporary", isPm25temporaryChecked], ["pm25nonregulatory", isPm25nonregulatoryChecked]])}
										className="spaciousStart"
									>
										<div className="spaciousText">
											<span>
												PM<sub style={{ marginRight: "-8px", verticalAlign: "sub" }}>2.5</sub>
											</span>
											<span>Fine Particles</span>
										</div>
										<div className="navBarMenuDropdownItemInputBox">
											<input
												type="checkbox"
												checked={isPm25Checked}
												onChange={(e) =>
													handleLayerGroupChange(
														setIsPm25Checked,
														setIsPm25regulatoryChecked,
														setIsPm25temporaryChecked,
														setIsPm25nonregulatoryChecked
													)(e.target.checked)
												}
												style={{ transform: "scale(2)" }}
											/>
										</div>
									</label>
								</div>
							</div>
						</div>
					)}
				</div>
				<div ref={adaDropdownRef} className="navBarMenuItem"
						onClick={() => setShowADA(!showADA)}>
					<button
						className={showADA ? "navButtonClicked" : "navButton"}
						id="nav_ada"
						name="nav_ada"
					>
						<img className="navButtonIcon" src={showADA ? adaSelectedImg : adaImg} alt="ADA" />
						<span className="navBarMenuItem text">ADA</span>
					</button>
					{showADA && (
						<div onClick={(e) => handleDropdownClick(e)} className={`navBarMenuDropdown adaMenu`}>
							<div className="navBarMenuDropdownHeader">ACCESSIBILITY</div>
							<div className="navBarMenuDropdownItem">
								<label>
									<div className="navBarMenuDropdownItemImageBox">
										<img src={aqiColorWheel} alt="ColorVision Assist icon" />
									</div>
									<div className="navBarMenuDropdownItemTextBox">ColorVision Assist</div>
									<div className="navBarMenuDropdownItemInputBox">
										<input type="checkbox" checked={adaIcons} onChange={() => setAdaIcons()} />
									</div>
								</label>
							</div>
							<div className="navBarMenuDropdownItem">
								<span className="navBarMenuDropdownItemFullTextBox">
									Adjusts AQI colors to enhance contrast to assist individuals with color vision deficiencies.
								</span>
							</div>
						</div>
					)}
				</div>
				<div className="navBarMenuItem"
						onClick={() => setShowFAQ(!showFAQ)}>
					<button
						className={showFAQ ? "navButtonClicked" : "navButton"}
						id="nav_faq"
						name="nav_faq"
					>
						<img className="navButtonIcon" src={showFAQ ? faqSelectedImg : faqImg} alt="FAQ" />
						<span className="navBarMenuItem text">FAQ</span>
					</button>
					{showFAQ && (
						<div onClick={(e) => handleDropdownClick(e)} ref={faqDropdownRef} className={`navBarMenuDropdown faqMenu`}>
							<div className="navBarMenuDropdownHeader">FREQUENTLY ASKED QUESTIONS</div>
							<div className="navBarMenuDropdownItem">
								<div className="navBarMenuDropdownItemDetailsBox">
									<details>
										<summary>What is the Air Quality Index (AQI)?</summary>
										<p>The AQI is a tool developed by the EPA for communicating daily air quality in a format that is the same from state to state. It uses color-coded categories and provides statements for each category that tell you about air quality in your area, which groups of people may be affected, and steps you can take to reduce your exposure to air pollution. See <a href="https://www.airnow.gov/aqi/aqi-basics/using-air-quality-index" target="_blank" rel="noreferrer">Using the Air Quality Index </a> for more info.</p>
									</details>
									<details>
										<summary>What are the AQI categories and activity guidelines for PM2.5?</summary>
										<p>The AQI is divided into six categories. Each category corresponds to a different level of health concern. Each category also has a specific color. The color makes it easy for people to quickly determine whether air quality is reaching unhealthy levels in their communities. See the Air Quality Guide for Particle Pollution for more information on advisory levels: <a href="https://document.airnow.gov/air-quality-guide-for-particle-pollution.pdf" target="_blank" rel="noreferrer">Guide for Particle Pollution</a></p>
									</details>
									<details>
										<summary>What are the DOH categories and activity guidelines for SO2?</summary>
										<p>The Hawaii Department of Health (DOH) short-term SO2 advisory levels are also divided into six categories. Each category corresponds to a different level of health concern. Each category also has a specific color. The color makes it easy for people to quickly determine whether air quality is reaching unhealthy levels in their communities. Short-term exposures to sulfur dioxide (SO2) can harm the human respiratory system and make breathing difficult. People with asthma, particularly children, are sensitive to these effects of SO2. See the Hawaii Short Term SO2 Advisory for more information on advisory levels: <a href="http://www.hiso2index.info/" target="_blank" rel="noreferrer">Hawaii Short Term SO2 Advisory</a></p>
									</details>
									<details>
										<summary>What is the difference between Regulatory, Temporary, and Non-regulatory ambient air monitors?</summary>
										<p>
											<div>
												<p>Regulatory Ambient Air Monitors</p>
												<ul>
													<li>Installed at fixed locations and operated to comply with federal, state, and local air quality regulations.</li>
													<li>Part of a network of monitoring stations used to determine compliance with National Ambient Air Quality Standards (NAAQS) set by the U.S. Environmental Protection Agency.</li>
													<li>Data must meet stringent data quality assurance and quality control procedures.</li>
													<li>Data is reported to regulatory agencies and made publicly available.</li>
												</ul>
											</div>
											<div>
												<p>Temporary Ambient Air Monitors</p>
												<ul>
													<li>Deployed for a limited period to address specific short-term air quality events such as wildfires, volcanic eruptions, or industrial releases.</li>
													<li>Not part of a permanent regulatory network.</li>
													<li>Data is used for assessment and planning purposes.</li>
												</ul>
											</div>
											<div>
												<p>Non-Regulatory Ambient Air Monitors</p>
												<ul>
													<li>Use for research, community awareness, education, and private purposes.</li>
													<li>Not directly involved with regulatory compliance.</li>
												</ul>
											</div>
										</p>
									</details>
								</div>
								<a href="#more-faqs">More FAQs</a>
							</div>
						</div>
					)}
				</div>
			</div>
		</nav>
	);
};

export default NavBar;
