import React from "react";
import { createRoot } from "react-dom/client";
import { Amplify } from "aws-amplify";
import config from "./aws-exports";
import App from "./App"; // import your App component

Amplify.configure(config);

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
	<React.StrictMode>
		<App tab="home" />
	</React.StrictMode>
);
