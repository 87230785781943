// Borderless
import circleGood from "../assets/circle-good.png";
import circleModerate from "../assets/circle-moderate.png";
import circleUsg from "../assets/circle-usg.png";
import circleUnhealthy from "../assets/circle-unhealthy.png";
import circleVeryunhealthy from "../assets/circle-veryunhealthy.png";
import circleHazardous from "../assets/circle-hazardous.png";
import circleNA from "../assets/circle-na.png";

import triangleGood from "../assets/triangle-good.png";
import triangleModerate from "../assets/triangle-moderate.png";
import triangleUsg from "../assets/triangle-usg.png";
import triangleUnhealthy from "../assets/triangle-unhealthy.png";
import triangleVeryunhealthy from "../assets/triangle-veryunhealthy.png";
import triangleHazardous from "../assets/triangle-hazardous.png";
import triangleNA from "../assets/triangle-na.png";

import squareGood from "../assets/square-good.png";
import squareModerate from "../assets/square-moderate.png";
import squareUsg from "../assets/square-usg.png";
import squareUnhealthy from "../assets/square-unhealthy.png";
import squareVeryunhealthy from "../assets/square-veryunhealthy.png";
import squareHazardous from "../assets/square-hazardous.png";
import squareNA from "../assets/square-na.png";

// Black border
import circleGoodBlack from "../assets/circle-good-bb.png";
import circleModerateBlack from "../assets/circle-moderate-bb.png";
import circleUsgBlack from "../assets/circle-usg-bb.png";
import circleUnhealthyBlack from "../assets/circle-unhealthy-bb.png";
import circleVeryunhealthyBlack from "../assets/circle-veryunhealthy-bb.png";
import circleHazardousBlack from "../assets/circle-hazardous-bb.png";
import circleNaBlack from "../assets/circle-na-bb.png";

import triangleGoodBlack from "../assets/triangle-good-bb.png";
import triangleModerateBlack from "../assets/triangle-moderate-bb.png";
import triangleUsgBlack from "../assets/triangle-usg-bb.png";
import triangleUnhealthyBlack from "../assets/triangle-unhealthy-bb.png";
import triangleVeryunhealthyBlack from "../assets/triangle-veryunhealthy-bb.png";
import triangleHazardousBlack from "../assets/triangle-hazardous-bb.png";
import triangleNaBlack from "../assets/triangle-na-bb.png";

import squareGoodBlack from "../assets/square-good-bb.png";
import squareModerateBlack from "../assets/square-moderate-bb.png";
import squareUsgBlack from "../assets/square-usg-bb.png";
import squareUnhealthyBlack from "../assets/square-unhealthy-bb.png";
import squareVeryunhealthyBlack from "../assets/square-veryunhealthy-bb.png";
import squareHazardousBlack from "../assets/square-hazardous-bb.png";
import squareNaBlack from "../assets/square-na-bb.png";

// Black border, ADA compliant
import circleGoodBlackADA from "../assets/circle-good-bb-ada.png";
import circleModerateBlackADA from "../assets/circle-moderate-bb-ada.png";
import circleUsgBlackADA from "../assets/circle-usg-bb-ada.png";
import circleUnhealthyBlackADA from "../assets/circle-unhealthy-bb-ada.png";
import circleVeryunhealthyBlackADA from "../assets/circle-veryunhealthy-bb-ada.png";
import circleHazardousBlackADA from "../assets/circle-hazardous-bb-ada.png";

import triangleGoodBlackADA from "../assets/triangle-good-bb-ada.png";
import triangleModerateBlackADA from "../assets/triangle-moderate-bb-ada.png";
import triangleUsgBlackADA from "../assets/triangle-usg-bb-ada.png";
import triangleUnhealthyBlackADA from "../assets/triangle-unhealthy-bb-ada.png";
import triangleVeryunhealthyBlackADA from "../assets/triangle-veryunhealthy-bb-ada.png";
import triangleHazardousBlackADA from "../assets/triangle-hazardous-bb-ada.png";

import squareGoodBlackADA from "../assets/square-good-bb-ada.png";
import squareModerateBlackADA from "../assets/square-moderate-bb-ada.png";
import squareUsgBlackADA from "../assets/square-usg-bb-ada.png";
import squareUnhealthyBlackADA from "../assets/square-unhealthy-bb-ada.png";
import squareVeryunhealthyBlackADA from "../assets/square-veryunhealthy-bb-ada.png";
import squareHazardousBlackADA from "../assets/square-hazardous-bb-ada.png";

// Gray border
import circleGoodGrey from "../assets/circle-good-gb.png";
import circleModerateGrey from "../assets/circle-moderate-gb.png";
import circleUsgGrey from "../assets/circle-usg-gb.png";
import circleUnhealthyGrey from "../assets/circle-unhealthy-gb.png";
import circleVeryunhealthyGrey from "../assets/circle-veryunhealthy-gb.png";
import circleHazardousGrey from "../assets/circle-hazardous-gb.png";
import circleNaGrey from "../assets/circle-na-gb.png";

import triangleGoodGrey from "../assets/triangle-good-gb.png";
import triangleModerateGrey from "../assets/triangle-moderate-gb.png";
import triangleUsgGrey from "../assets/triangle-usg-gb.png";
import triangleUnhealthyGrey from "../assets/triangle-unhealthy-gb.png";
import triangleVeryunhealthyGrey from "../assets/triangle-veryunhealthy-gb.png";
import triangleHazardousGrey from "../assets/triangle-hazardous-gb.png";
import triangleNaGrey from "../assets/triangle-na-gb.png";

import squareGoodGrey from "../assets/square-good-gb.png";
import squareModerateGrey from "../assets/square-moderate-gb.png";
import squareUsgGrey from "../assets/square-usg-gb.png";
import squareUnhealthyGrey from "../assets/square-unhealthy-gb.png";
import squareVeryunhealthyGrey from "../assets/square-veryunhealthy-gb.png";
import squareHazardousGrey from "../assets/square-hazardous-gb.png";
import squareNaGrey from "../assets/square-na-gb.png";

const pm25Scale = new Map();
pm25Scale.set("good", {
	label: "Good",
	rank: 1,
	min: 0,
	max: 50,
	color: "#00E200",
	adaColor: "#9FFD92",
	textColor: "#333333",
	description: "Considered satisfactory, and air pollution poses little or no risk.",
	recommendation: {
		"sensitive-groups": "",
		"people-exp-health-effects": "",
		"everyone-else": "",
	},
});
pm25Scale.set("mod", {
	label: "Moderate",
	rank: 2,
	min: 51,
	max: 100,
	color: "#F3E812",
	adaColor: "#FFC902",
	textColor: "#333333",
	description:
		"Acceptable; however, for some pollutants there may be a moderate health concern for a very small number of people who are unusually sensitive to air pollution.",
	recommendation: {
		"sensitive-groups": "",
		"people-exp-health-effects": "",
		"everyone-else": "",
	},
});
pm25Scale.set("usg", {
	label: "Unhealthy for Sensitive Groups",
	rank: 3,
	min: 101,
	max: 150,
	color: "#F27E20",
	adaColor: "#FF8305",
	textColor: "#333333",
	description: "Members of sensitive groups may experience health effects. The general public is not likely to be affected.",
	recommendation: {
		"sensitive-groups": "",
		"people-exp-health-effects": "",
		"everyone-else": "",
	},
});
pm25Scale.set("unh", {
	label: "Unhealthy",
	rank: 4,
	min: 151,
	max: 200,
	color: "#E82428",
	adaColor: "#F02501",
	textColor: "#FFFFFF",
	description:
		"Some members of the general public may experience health effects; members of sensitive groups may experience more serious health effects.",
	recommendation: {
		"sensitive-groups": "",
		"people-exp-health-effects": "",
		"everyone-else": "",
	},
});
pm25Scale.set("vunh", {
	label: "Very Unhealthy",
	rank: 5,
	min: 201,
	max: 300,
	color: "#8A4095",
	adaColor: "#891897",
	textColor: "#FFFFFF",
	description: "Health alert: The risk of health effects is increased for everyone.",
	recommendation: {
		"sensitive-groups": "",
		"people-exp-health-effects": "",
		"everyone-else": "",
	},
});
pm25Scale.set("haz", {
	label: "Hazardous",
	rank: 6,
	min: 301,
	max: Infinity,
	color: "#630A34",
	adaColor: "#640316",
	textColor: "#FFFFFF",
	description: "Health warning of emergency conditions: everyone is more likely to be affected.",
	recommendation: {
		"sensitive-groups": "",
		"people-exp-health-effects": "",
		"everyone-else": "",
	},
});
pm25Scale.set("na", {
	label: "N/A",
	rank: 0,
	min: -Infinity,
	max: -Infinity,
	color: "#D9D9D9",
	adaColor: "#D9D9D9",
	textColor: "#333333",
	description: "N/A",
	recommendation: {
		"sensitive-groups": "",
		"people-exp-health-effects": "",
		"everyone-else": "",
	},
});
const so2Scale = new Map();
so2Scale.set("good", {
	label: "Good",
	rank: 1,
	min: 0,
	max: 0.1,
	color: "#00E200",
	textColor: "#333333",
	description: "Considered satisfactory & poses little or no risk",
	recommendation: {
		"sensitive-groups": "",
		"people-exp-health-effects": "",
		"everyone-else": "Potential health effects not expected",
	},
});
so2Scale.set("mod", {
	label: "Moderate",
	rank: 2,
	min: 0.11,
	max: 0.2,
	color: "#F3E812",
	textColor: "#333333",
	description: "Acceptable, however, may be moderate health concern for small number of people",
	recommendation: {
		"sensitive-groups": "Be aware that levels are slightly elevated ",
		"people-exp-health-effects":
			"If you experience breathing difficulties, such as chest tightness or wheezing, stop activities, use a rescue inhaler and find a place to sit down and rest.",
		"everyone-else": "Potential health effects not expected, however actions to reduce exposure to vog may be useful",
	},
});
so2Scale.set("usg", {
	label: "Unhealthy for Sensitive Groups",
	rank: 3,
	min: 0.21,
	max: 1.0,
	color: "#F27E20",
	textColor: "#333333",
	description:
		"Members in sensitive groups, including healthy individuals with mild asthma, may experience health effects. They may be affected at lower levels than general public. Toward the upper end of this range, most asthmatics who are active outdoors are likely to experience some breathing difficulties. General public not expected to be affected in this range",
	recommendation: {
		"sensitive-groups": "Avoid outdoor activities that cause heavy breathing or breathing through the mouth",
		"people-exp-health-effects":
			"If you experience breathing difficulties, such as chest tightness or wheezing, stop activities, use a rescue inhaler and find a place to sit down and rest.",
		"everyone-else": "Potential health effects not expected, however actions to reduce exposure to vog may be useful",
	},
});
so2Scale.set("unh", {
	label: "Unhealthy",
	rank: 4,
	min: 1.01,
	max: 3.0,
	color: "#E82428",
	textColor: "#FFFFFF",
	description:
		"Everyone may begin to experience health effects. Members of sensitive groups may experience more serious health effects.",
	recommendation: {
		"sensitive-groups":
			"Everyone may begin to experience health effects. Members of sensitive groups may experience more serious health effects.",
		"people-exp-health-effects": "Consider leaving the area",
		"everyone-else": "Avoid outdoor activities that cause heavy breathing or breathing through the mouth",
	},
});
so2Scale.set("vunh", {
	label: "Very Unhealthy",
	rank: 5,
	min: 3.01,
	max: 5.0,
	color: "#630A34",
	textColor: "#FFFFFF",
	description: "Triggers health alert, meaning everyone may experience more serious health effects.",
	recommendation: {
		"sensitive-groups": "Avoid outdoor activities & remain indoors",
		"people-exp-health-effects": "Leave the area & seek medical help",
		"everyone-else": "Avoid outdoor activities & remain indoors",
	},
});
so2Scale.set("haz", {
	label: "Hazardous",
	rank: 6,
	min: 5.01,
	max: Infinity,
	color: "#630A34",
	textColor: "#FFFFFF",
	description: "Triggers health warnings of emergency conditions. Entire population is more likely to be affected.",
	recommendation: {
		"sensitive-groups": "Avoid outdoor activities & remain indoors. Leave the area if directed by Civil Defense ",
		"people-exp-health-effects": "Leave the area & seek medical help",
		"everyone-else": "Avoid outdoor activities & remain indoors. Leave the area if directed by Civil Defense",
	},
});
so2Scale.set("na", {
	label: "N/A",
	rank: 0,
	min: -Infinity,
	max: -Infinity,
	color: "#D9D9D9",
	textColor: "#333333",
	description: "N/A",
	recommendation: {
		"sensitive-groups": "",
		"people-exp-health-effects": "",
		"everyone-else": "",
	},
});

export function getCategory(pollutant, value) {
	if (pollutant === "pm25") {
		return getPm25Category(value);
	} else if (pollutant === "so2") {
		return getSo2Category(value);
	}
}

function getPm25Category(pm25) {
	for (let [, category] of pm25Scale) {
		if (pm25 >= category.min && pm25 <= category.max) {
			return category;
		}
	}
	return pm25Scale.get("na");
}

function getSo2Category(so2) {
	for (let [, category] of so2Scale) {
		if (so2 >= category.min && so2 <= category.max) {
			return category;
		}
	}
	return so2Scale.get("na");
}

export function getColor(pollutant, value) {
	if (pollutant === "pm25") {
		return getPm25Color(value);
	} else if (pollutant === "so2") {
		return getSo2Color(value);
	}
}
export function getPm25Color(pm25) {
	return getPm25Category(pm25).color;
}
export function getPm25AdaColor(pm25) {
	return getPm25Category(pm25).adaColor;
}
export function getSo2Color(so2) {
	return getSo2Category(so2).color;
}
export function getCategoryTextColor(pollutant, value) {
	const category = getCategory(pollutant, value);
	return category.textColor;
}

export function getRgbColor(pollutant, value, alpha) {
	let color = pollutant === "pm25" ? getPm25Color(value) : getSo2Color(value);

	// Remove the hash at the start if it's there
	color = color.replace(/^#/, ""); // Parse the hex string
	let r, g, b;
	r = parseInt(color.substring(0, 2), 16);
	g = parseInt(color.substring(2, 4), 16);
	b = parseInt(color.substring(4, 6), 16);

	return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export function getCategoryLabel(pollutant, value) {
	if (pollutant === "pm25") {
		return getPm25Label(value);
	} else if (pollutant === "so2") {
		return getSo2Label(value);
	}
}
export function getPm25Label(pm25) {
	return getPm25Category(pm25).label;
}
export function getSo2Label(so2) {
	return getSo2Category(so2).label;
}

export function worstPollutant(pm25, so2) {
	// If the site doesn't have one both pollutants, we know which one is worse
	if (pm25 === undefined) {
		return "so2";
	} else if (so2 === undefined) {
		return "pm25";
	}
	const pm25Category = getPm25Category(getValue(pm25));
	const so2Category = getSo2Category(getValue(so2));
	if (pm25Category.rank > so2Category.rank) {
		return "pm25";
	} else if (pm25Category.rank < so2Category.rank) {
		return "so2";
	} else {
		if (
			(getValue(pm25) - pm25Category.min) / (pm25Category.max - pm25Category.min) >
			(getValue(so2) - so2Category.min) / (so2Category.max - so2Category.min)
		) {
			return "pm25";
		} else {
			return "so2";
		}
		// TODO: Tiebreaker?
	}
}

export function getRegulatoryAqiIcon(value, pollutant) {
	const category = pollutant === "pm25" ? getPm25Category(value) : getSo2Category(value);
	if (category.label === "Good") {
		return circleGood;
	} else if (category.label === "Moderate") {
		return circleModerate;
	} else if (category.label === "Unhealthy for Sensitive Groups") {
		return circleUsg;
	} else if (category.label === "Unhealthy") {
		return circleUnhealthy;
	} else if (category.label === "Very Unhealthy") {
		return circleVeryunhealthy;
	} else if (category.label === "Hazardous") {
		return circleHazardous;
	} else {
		return circleNA;
	}
}

export function getTemporaryAqiIcon(value, pollutant) {
	const category = pollutant === "pm25" ? getPm25Category(value) : getSo2Category(value);
	if (category.label === "Good") {
		return triangleGood;
	} else if (category.label === "Moderate") {
		return triangleModerate;
	} else if (category.label === "Unhealthy for Sensitive Groups") {
		return triangleUsg;
	} else if (category.label === "Unhealthy") {
		return triangleUnhealthy;
	} else if (category.label === "Very Unhealthy") {
		return triangleVeryunhealthy;
	} else if (category.label === "Hazardous") {
		return triangleHazardous;
	} else {
		return triangleNA;
	}
}

export function getNonRegulatoryAqiIcon(value, pollutant) {
	const category = pollutant === "pm25" ? getPm25Category(value) : getSo2Category(value);
	if (category.label === "Good") {
		return squareGood;
	} else if (category.label === "Moderate") {
		return squareModerate;
	} else if (category.label === "Unhealthy for Sensitive Groups") {
		return squareUsg;
	} else if (category.label === "Unhealthy") {
		return squareUnhealthy;
	} else if (category.label === "Very Unhealthy") {
		return squareVeryunhealthy;
	} else if (category.label === "Hazardous") {
		return squareHazardous;
	} else {
		return squareNA;
	}
}

export function getRegulatoryAqiIconBlack(value, pollutant, ada=false, expired= false) {
	const category = pollutant === "pm25" ? getPm25Category(value) : getSo2Category(value);
	if (expired) {
		return circleNaBlack;
	} else if (category.label === "Good") {
		return ada ? circleGoodBlackADA : circleGoodBlack;
	} else if (category.label === "Moderate") {
		return  ada ? circleModerateBlackADA : circleModerateBlack;
	} else if (category.label === "Unhealthy for Sensitive Groups") {
		return  ada ? circleUsgBlackADA : circleUsgBlack;
	} else if (category.label === "Unhealthy") {
		return  ada ? circleUnhealthyBlackADA : circleUnhealthyBlack;
	} else if (category.label === "Very Unhealthy") {
		return  ada ? circleVeryunhealthyBlackADA : circleVeryunhealthyBlack;
	} else if (category.label === "Hazardous") {
		return  ada ? circleHazardousBlackADA : circleHazardousBlack;
	} else {
		return circleNaBlack;
	}
}

export function getTemporaryAqiIconBlack(value, pollutant, ada=false, expired= false) {
	const category = pollutant === "pm25" ? getPm25Category(value) : getSo2Category(value);
	if (expired) {
		return triangleNaBlack;
	} else if (category.label === "Good") {
		return  ada ? triangleGoodBlackADA : triangleGoodBlack;
	} else if (category.label === "Moderate") {
		return ada ? triangleModerateBlackADA : triangleModerateBlack;
	} else if (category.label === "Unhealthy for Sensitive Groups") {
		return ada ? triangleUsgBlackADA : triangleUsgBlack;
	} else if (category.label === "Unhealthy") {
		return ada ? triangleUnhealthyBlackADA : triangleUnhealthyBlack;
	} else if (category.label === "Very Unhealthy") {
		return ada ? triangleVeryunhealthyBlackADA : triangleVeryunhealthyBlack;
	} else if (category.label === "Hazardous") {
		return ada ? triangleHazardousBlackADA : triangleHazardousBlack;
	} else {
		return triangleNaBlack;
	}
}

export function getNonRegulatoryAqiIconBlack(value, pollutant, ada=false, expired= false) {
	const category = pollutant === "pm25" ? getPm25Category(value) : getSo2Category(value);
	if (expired) {
		return squareNaBlack;
	} else if (category.label === "Good") {
		return ada ? squareGoodBlackADA : squareGoodBlack;
	} else if (category.label === "Moderate") {
		return ada ? squareModerateBlackADA : squareModerateBlack;
	} else if (category.label === "Unhealthy for Sensitive Groups") {
		return ada ? squareUsgBlackADA : squareUsgBlack;
	} else if (category.label === "Unhealthy") {
		return ada ? squareUnhealthyBlackADA : squareUnhealthyBlack;
	} else if (category.label === "Very Unhealthy") {
		return ada ? squareVeryunhealthyBlackADA : squareVeryunhealthyBlack;
	} else if (category.label === "Hazardous") {
		return ada ? squareHazardousBlackADA : squareHazardousBlack;
	} else {
		return squareNaBlack;
	}
}

export function getRegulatoryAqiIconGrey(value, pollutant) {
	const category = pollutant === "pm25" ? getPm25Category(value) : getSo2Category(value);
	if (category.label === "Good") {
		return circleGoodGrey;
	} else if (category.label === "Moderate") {
		return circleModerateGrey;
	} else if (category.label === "Unhealthy for Sensitive Groups") {
		return circleUsgGrey;
	} else if (category.label === "Unhealthy") {
		return circleUnhealthyGrey;
	} else if (category.label === "Very Unhealthy") {
		return circleVeryunhealthyGrey;
	} else if (category.label === "Hazardous") {
		return circleHazardousGrey;
	} else {
		return circleNaGrey;
	}
}

export function getTemporaryAqiIconGrey(value, pollutant) {
	const category = pollutant === "pm25" ? getPm25Category(value) : getSo2Category(value);
	if (category.label === "Good") {
		return triangleGoodGrey;
	} else if (category.label === "Moderate") {
		return triangleModerateGrey;
	} else if (category.label === "Unhealthy for Sensitive Groups") {
		return triangleUsgGrey;
	} else if (category.label === "Unhealthy") {
		return triangleUnhealthyGrey;
	} else if (category.label === "Very Unhealthy") {
		return triangleVeryunhealthyGrey;
	} else if (category.label === "Hazardous") {
		return triangleHazardousGrey;
	} else {
		return triangleNaGrey;
	}
}

export function getNonRegulatoryAqiIconGrey(value, pollutant) {
	const category = pollutant === "pm25" ? getPm25Category(value) : getSo2Category(value);
	if (category.label === "Good") {
		return squareGoodGrey;
	} else if (category.label === "Moderate") {
		return squareModerateGrey;
	} else if (category.label === "Unhealthy for Sensitive Groups") {
		return squareUsgGrey;
	} else if (category.label === "Unhealthy") {
		return squareUnhealthyGrey;
	} else if (category.label === "Very Unhealthy") {
		return squareVeryunhealthyGrey;
	} else if (category.label === "Hazardous") {
		return squareHazardousGrey;
	} else {
		return squareNaGrey;
	}
}

export function getMapIcon(values, instrumentType, ada=false) {
	const pollutant = worstPollutant(values[0], values[1]);
	const value = pollutant === "pm25" ? getValue(values[0]) : getValue(values[1]);
	const expired = pollutant === "pm25" ? getExpired(values[0]) : getExpired(values[1])
	const monitorType = monitorTypeFromInstrumentType(instrumentType);
	if (monitorType === "regulatory") {
		return getRegulatoryAqiIconBlack(value, pollutant, ada, expired);
	} else if (monitorType === "temporary") {
		return getTemporaryAqiIconBlack(value, pollutant, ada, expired);
	} else if (monitorType === "nonregulatory") {
		return getNonRegulatoryAqiIconBlack(value, pollutant, ada, expired);
	}
}

export function monitorTypeFromInstrumentType(instrumentType) {
	return instrumentType.replace("pm25","").replace("so2","");
}

export function getMapIconSize(type) {
	if (type === "regulatory" || type === "temporary") {
		return 20;
	}
	return 14;
}

export function getMonitorTypeString(site) {
	if (site.agencycode === "PAR") {
		return "Non-Regulatory";
	}
	if (site.agencycode === "HI1") {
		if (!site.fullaqscode.startsWith('MM')) {
			return "Regulatory";
		} else {
			return "Temporary";
		}
	}
}

function isPlainObject(value) {
	return value !== null && typeof value === 'object' && value.constructor === Object;
}

export function getValue(datapoint) {
	// Exists to get the appropriate value variable depending on pollutant
	let value = datapoint;
	if (isPlainObject(datapoint)) {
		if (datapoint.pollutant === "PM2.5") {
			// We're after the AQI, not the raw value
			if ('aqivalue' in datapoint && datapoint.aqivalue >= 0) {
				value = datapoint.aqivalue;
			} else {
				value = datapoint.value;
			}
		} else if (datapoint.pollutant === "SO2-15") {
			value = datapoint.value;
		}
	}
	return value;
}

export function getExpired(datapoint) {
	let expired = false;
	if (isPlainObject(datapoint)) {
		if (datapoint.expired) {
			expired = datapoint.expired;
		}
	}
	return expired;
}

export function roundToNearestTenth(number) {
	return number < 1.0 ? Math.round(number * 10) / 10 : Math.round(number);
  }