import React, { useState } from "react";
import "../styles/Advisory.css";


function Advisory({ title, message, linkPrompt, linkText, linkHref }) {
	const [isCollapsed, setIsCollapsed] = useState(false);

	const toggleCollapse = () => {
		setIsCollapsed(!isCollapsed);
	};

	return (
		<div className="advisory" onClick={toggleCollapse}>
			{title && <div className="advisoryTitle">{title}</div>}
			<div className="advisoryMessage" style={{display: isCollapsed ? "none" : "block" }}>
				{message}
				{linkHref && (
					<span className="advisoryLinkText">
						{linkPrompt}
						<a className="advisoryLinkStyle" href={linkHref} target="_blank" rel="noopener noreferrer">
							{linkText}
						</a>
					</span>
				)}
			</div>
			<span className="collapseButtonStyle">{isCollapsed ? "More \u2228" : "Less \u2227"}</span>
		</div>
	);
}

export default Advisory;

// Example of usage:
// <Advisory
//   title="Air Quality Advisory"
//   message="Volcanic emissions are affecting this area. Lorem ipsum dolor sit amet."
//   linkText="More info"
//   linkHref="https://link.to/more-info"
// />
