function generateSubscriptText(base, subscript, postscript = "", margin = true) {
	return (
		<span style={{alignItems: "center", justifyItems: "center", textJustify: "center", marginLeft: margin ? "20px" : "0px", marginBottom: "5px"}}>
			{base}
			<sub>{subscript}</sub>
			{postscript}
		</span>
	);
}
const so2Abbrev = generateSubscriptText("SO", "2");
const so2CenteredAbbrev = generateSubscriptText("SO", "2", "", false)
const so2FullName = generateSubscriptText("SO", "2", " Sulfur Dioxide");
const so2TimeAbbrev15min = generateSubscriptText("15-min SO", "2");
const so2TimeAbbrev1hr = generateSubscriptText("1-hour SO", "");
const pm25Abbrev = generateSubscriptText("PM", "2.5");
const pm25CenteredAbbrev = generateSubscriptText("PM", "2.5", "", false)
const pm25FullName = generateSubscriptText("PM", "2.5", " Fine Particles");
const pm25TimeAbbrev10min = generateSubscriptText("10-minute PM", "2.5");
const pm25TimeAbbrev1hr = generateSubscriptText("1-hour PM", "2.5");
export const hawaiiTimeAbbrev = "HST";

export function getSo2Text(oneHour) {
	return new Map([
		["abbrev", so2Abbrev],
		["centeredAbbrev", so2CenteredAbbrev],
		["description", so2FullName],
		["duration", oneHour ? so2TimeAbbrev1hr : so2TimeAbbrev15min],
		["timezone", hawaiiTimeAbbrev],
		["scale", "ppm"],
		["key", "so2"],
	]);
}

export function getPm25Text(oneHour) {
	//Invert the oneHour as it's what HI DOH uses for permanent monitors
	oneHour = !oneHour;
	return new Map([
		["abbrev", pm25Abbrev],
		["centeredAbbrev", pm25CenteredAbbrev],
		["description", pm25FullName],
		["duration", oneHour ? pm25TimeAbbrev1hr : pm25TimeAbbrev10min],
		["timezone", hawaiiTimeAbbrev],
		["scale", "AQI"],
		["key", "pm25"],
	]);
}

export function getText(pollutant, oneHour=false) {
	if (pollutant === "so2") {
		return getSo2Text(oneHour);
	} else if (pollutant === "pm25") {
		return getPm25Text(oneHour);
	}
}

export function getPollutantTextSize(pollutant, scaleFactor) {
	if (pollutant === "so2") {
		return (40*scaleFactor)+"px";
	} else {
		return (48*scaleFactor)+"px";
	}
}

export function getAgencyText(code) {
	if (code === "PAR") {
		return (
			<a href="https://www2.purpleair.com" target="_blank" rel="noopener noreferrer">PurpleAir</a>
		)
	}
	return "Hawaii State Department of Health"
}